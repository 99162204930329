import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store =  new Vuex.Store({
	state: {
		language : {
			type : 1, // 语言：1：中文，2：英文
		},
		cn_en_list : {},
	},
	mutations: {
		LanguageFn(state , payload){
			this.state.language = payload
		},
		//中英翻译
		Cn_En(state , payload){
			this.state.cn_en_list = payload
		},
	},
	actions: {
	},
	modules: {
	}
})
export default store
