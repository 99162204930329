<template>
    <div id="app">
        <keep-alive v-if="isRouterAlive">
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
import {config , mc_list} from './api/api'
export default {
    provide () {
        return {
            reload: this.reload,
        }
    },
    data(){
        return {
            isRouterAlive : true,
        }
    },
    mounted(){
        // document.onkeydown = function() {
        //     var e = window.event || arguments[0];
        //     if (e.keyCode == 123) {
        //         return false;
        //     }
        // }
        // 屏蔽鼠标右键
        document.oncontextmenu = function() {
            return false;
        }

        mc_list({'language' : 1}).then(res => {
            this.$store.commit('Cn_En' , res.data.data)
            this.$emit("reload");
        })
        config().then(res => {
            if(res.data.code == 10000){
                var obj = {}
                res.data.data.forEach(item => {
                    obj[item.key] = item
                })
                obj.nowTime = res.data.time * 1000
                localStorage.setItem("config", JSON.stringify(obj));
            }
        })
    },
    methods : {
        reload () {
            this.isRouterAlive = false
            this.$nextTick(() => {this.isRouterAlive = true})
        },
    }
}
</script>

<style lang="less">

</style>
